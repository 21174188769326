import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../shared/services/product.service';
import { Product } from 'src/app/modals/product.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
  products: Product[];
  public banners = [];
  public slides = [
    { title: 'FICTIONAL CLIMBING CO.', subtitle: 'Welcome to Fictional, where the rock gets real.', image: 'assets/images/carousel/banner1.png' }
  ];

  constructor(private productService: ProductService) { }

  ngOnInit() {


 this.productService.getProducts()
 .subscribe(
   (product: Product[]) => {
     this.products = product
   }
 )

  }






}
